import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import landingBanner from '../../assets/images/landing-banner.png';
import landingMobileBanner from '../../assets/images/landing_mobile_banner.png';
import './Home.scss';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';

function Home() {

    // Language global states
    const language = useAppSelector(state => state.languageState.selectedLanguage)
    const [currentLanguage, setcurrentLanguage] = useState("ENG");
    const languageSetHandler = () => setcurrentLanguage(language);

    // Watchers for language and current question change
    useEffect(() => {
        languageSetHandler();
    }, [language]);

    return (
        <>
            <div className="d-flex parentHomeDiv align-items-center d-flex flex-column justify-content-center ">
                <div className='letsBeginDivHome d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex flex-column itemsDivHome'>

                        <div className='headerTextHomeBottom'>
                            {currentLanguage === "ENG" ? "Let's find the " : "Encontremos el mejor "} <br />
                            {currentLanguage === "ENG" ? 'best shelter for you' : 'refugio para ti'}
                        </div>

                        <div className='primaryTextDivHome'>
                            {currentLanguage === "ENG" ? 'You must help us with basic information in ' : 'Necesitamos información básica para encontrar '}<br />
                            {currentLanguage === "ENG" ? 'order to find the most suitable shelter.' : 'el refugio más adecuado.'}
                        </div>

                        <div className='warningDisclosureText'>
                            <FontAwesomeIcon className='warningIcon' icon={faTriangleExclamation} />
                            {currentLanguage === "ENG" ? 'The information shared in this process, will ' : 'La información compartida en este proceso será '} <br />
                            {currentLanguage === "ENG" ? 'be confidential and not stored' : 'confidencial y no será guardada'}
                        </div>
                    </div>

                    <div className='btnDivHome d-flex justify-content-center align-items-center mt-5'>
                        <Link to='/form'>

                            <button className='btnClass'>
                                <span className='btnText'>
                                    {currentLanguage === "ENG" ? "Let's Begin" : "Vamos a empezar"}
                                </span>
                            </button>
                        </Link>
                    </div>

                </div>

                <div className='bannerImageDiv'>
                    <div className='mobileBannerImageDiv'>
                        <img src={landingMobileBanner} alt="landing banner" className='imageBannerClassMobile' />
                    </div>
                    <div className='topDivGradient'>
                        {/* Empty div */}
                    </div>
                    <div className='topDivGradientTwo'>
                        {/* Empty div */}
                    </div>
                    <img src={landingBanner} alt="landing banner" className='imageBannerClass' />

                    <div className='textTopDivBlackHome'>
                        <div className="headerTextHome topDivText">
                            {currentLanguage === "ENG" ? 'Our purpose is to help you in whatever you need.' : 'Nuestro propósito es ayudarte en lo que necesites.'}
                        </div>
                        <a href='http://www.projectrousseau.org/' target='_blank' rel="noreferrer">

                            <button className='btnClassInfo knowAboutBtn'>
                                <span className='btnTextInfo'>
                                    {currentLanguage === "ENG" ? 'Learn More' : 'Aprende más'}
                                </span>
                            </button>
                        </a>

                    </div>
                </div>

            </div>


        </>
    );
}

export default Home;
