import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import NavBar from './components/NavBar/Navbar';
import SideBar from './components/SideBar/SideBar';
import RouteConfig from './RouteConfig/RouteConfig';
import useComponentVisible from './store/hooks';

function App() {

  const [openSideBar, setopenSideBar] = useState(false);
  const [firstTimeLoad, setfirstTimeLoad] = useState(true);
  const { ref, valueToggle, setvalueToggle } = useComponentVisible(false);

  useEffect(() => {
    setopenSideBar(false);
  }, [valueToggle])


  const toggleSideBarhandler = (openSideBar: boolean) => {
    setopenSideBar(openSideBar);
    setfirstTimeLoad(false);
  }


  return (
    <div className="App">
      <BrowserRouter>
        <NavBar toggleSideBarhandler={toggleSideBarhandler} hideSideBar={openSideBar} />
        <div ref={ref}>

          <SideBar openSideBar={openSideBar} toggleSideBarhandler={setvalueToggle} firstTimeLoad={firstTimeLoad} />
        </div>
        <RouteConfig />
      </BrowserRouter>
    </div>
  );
}

export default App;
