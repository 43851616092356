import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import ShelterInfoModal from './ShelterInfoModal/ShelterInfoModal';
import './ShelterList.scss';



function ShelterList() {

    const location = useLocation();
    const { apiResponse, localData } = location.state as ComponentParam;
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [modalProp, setmodalProp] = useState()
    const shelterListRaw: [] = apiResponse;

    // intentional "reccomended" on top sorting
    // const [shelterList, setshelterList] = useState<any[]>([])
    // useEffect(() => {
    //     // Some initialization logic here
    //     const shelterListYes: any[] = [];
    //     const shelterListNo: any[] = [];
    //     shelterListRaw.forEach((item: any) => {
    //         if (item.recommended === "Y") shelterListYes.push(item)
    //         else shelterListNo.push(item)
    //     })
    //     setshelterList([...shelterListYes, ...shelterListNo]);

    // }, []);

    // GBV based sorting
    const [shelterList, setshelterList] = useState<any[]>([])
    useEffect(() => {
        // If gender based question is kept yes hardcoding of question identifier
        if (localData && localData.length && localData[6]?.questionID === "6" && localData[6].answer === "Yes") {
            const shelterListYes: any[] = [];
            const shelterListNo: any[] = [];
            shelterListRaw.forEach((item: any) => {
                if (item.gbvFlag === "Y") shelterListYes.push(item)
                else shelterListNo.push(item)
            })
            setshelterList([...shelterListYes, ...shelterListNo]);
        }
        else {
            setshelterList(shelterListRaw);
        }
    }, []);




    const startOverHandler = () => {
        return (event: React.MouseEvent) => {
            navigate('/form');
            event.preventDefault();
        }
    }
    const handleImageError = (e: any) => {
        e.target.style.display = 'none';
    }

    // Language global states
    const language = useAppSelector(state => state.languageState.selectedLanguage)
    const [currentLanguage, setcurrentLanguage] = useState("ENG");
    const languageSetHandler = () => setcurrentLanguage(language);

    // Watchers for language and current question change
    useEffect(() => {
        languageSetHandler();
    }, [language]);

    const handleModalOpener = (e: any, shelter: any) => {
        setModalShow(true);
        setmodalProp(shelter);
    }

    return (
        <div className={`d-flex flex-column justify-content-center align-items-center ${modalShow ? 'blurWrapper' : ''}`}>
            <div className=' d-flex flex-column justify-content-center resultBox align-items-center'>
                {
                    shelterList.length === 0 && (
                        <div className="noShelterText">
                            {currentLanguage === "ENG" ? 'There are no shelters availaible based on your response' : 'No hay refugios disponibles según su respuesta.'}
                        </div>
                    )
                }
                {
                    shelterList.length !== 0 && (
                        <div className=' d-flex flex-column justify-content-center upperTextResult'>
                            <div className='goodNewstext'>
                                {currentLanguage === "ENG" ? 'Good news, we found a few' : 'Buenas noticias, encontramos algunas'} <br />
                                {currentLanguage === "ENG" ? 'options for you that look like a good fit' : 'opciones para ti que se ajustan bien'}
                            </div>
                            <div className='goodNewsSubtext'>
                                {currentLanguage === "ENG" ? 'These are the options we found, click them to show more detail.' : 'Estas son las opciones que encontramos, haz clic en ellas para mostrar más detalles.'}
                            </div>
                        </div>
                    )
                }
                <div className='shelterList d-flex justify-content-center'>

                    {
                        shelterList.map((shelter: any, index) => {
                            const compelteAdd = shelter.address.buildingNumber + ' ' + shelter.address.streetName + ' ' + shelter.address.city;
                            const sheltTrains = shelter?.accessibleTrainLine.length > 6 ? shelter?.accessibleTrainLine.slice(0, 4).join(', ') + '...' : shelter?.accessibleTrainLine.join(', ');
                            return (
                                <React.Fragment key={index}>
                                    <div className='cardArea d-flex  justify-content-center align-items-center' onClick={(e) => handleModalOpener(e, shelter)}>
                                        <div className='imageShelter'>
                                            {shelter.imgURLs && <img onError={handleImageError} src={shelter.imgURLs[0].url} alt="shelter" className='shelterImg' />}
                                        </div>
                                        <div className='shelterDetailsDiv justify-content-center d-flex align-items-start flex-column m-2'>

                                            <div className={`nonMobileShelterTitle shelterTitle lowerFont ${shelter.shelterName.length > 21 ? 'lowerFont' : ''}`} >
                                                {shelter.shelterName}
                                            </div>
                                            <div className={`mobileShelterTitle shelterTitle lowerFont ${shelter.shelterName.length > 21 ? 'lowerFont' : ''}`} >
                                                {shelter.shelterName.length > 25 ? shelter.shelterName.substring(0, 13) + '...' : shelter.shelterName}
                                            </div>
                                            <div className='shlterAddress'>
                                                {compelteAdd.length > 27 ? compelteAdd.substring(0, 13) + '...' : compelteAdd}
                                            </div>
                                            {/* <div className='shlterAddress'>
                                                10:00 am - 5:30 pm
                                            </div>
                                            <div className='shelterSubtitleText'>
                                                {currentLanguage === "ENG" ? 'Registration Hours' : 'Horas de registro'}
                                            </div> */}

                                            {(shelter?.accessibleTrainLine.length !== 0) && (
                                                <>
                                                    <div className='shlterAddress mt-2'>
                                                        {sheltTrains.length > 15 ? sheltTrains.substring(0, 13) + '...' : sheltTrains}
                                                    </div>
                                                    <div className='mb-2 shelterSubtitleText'>
                                                        {currentLanguage === "ENG" ? 'Accessible Trains' : 'Trenes Accesibles'}
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                        {shelter.recommended === "Y" && <div className='recommendedClass '>
                                            <span className='recommendedText'>Recommended</span>

                                        </div>}
                                    </div>
                                </React.Fragment>)
                        }
                        )}
                </div>

            </div>


            <div className='startOverFix align-items-center d-flex justify-content-center p-4'>
                <div className='startOverDiv d-flex justify-content-center align-items-center '>
                    <div className='startOverBtn d-flex align-items-center justify-content-center' onClick={startOverHandler()}>
                        {currentLanguage === "ENG" ? 'Start New Search' : 'Iniciar nueva búsqueda'}
                    </div>
                </div>
            </div>

            <ShelterInfoModal
                language={currentLanguage}
                shelter={modalProp}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div >
    )
}

export default ShelterList;
