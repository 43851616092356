/* eslint-disable react-hooks/rules-of-hooks */
import { Link, useLocation } from 'react-router-dom';
import './SideBar.scss';

function SideBar(props: any) {

    const { openSideBar, firstTimeLoad, toggleSideBarhandler } = props;
    const location = useLocation();



    return (
        < >
            {!firstTimeLoad &&
                <div className={`sidebar-container d-flex flex-column justify-content-between align-items-start   ${openSideBar ? 'slide-in' : 'slide-out'}`}>
                    <div className="navigaiton-items d-flex flex-column justify-content-between" >
                        <div className={`navigaiton-item ${location.pathname === "/" ? 'bold-item' : ''} `} onClick={toggleSideBarhandler}>
                            <Link to="/">
                                Home
                            </Link>
                        </div>
                        <div className={`mt-2 navigaiton-item ${location.pathname === "/form" ? 'bold-item' : ''} `} onClick={toggleSideBarhandler}>
                            <Link to="form">
                                Search a shelter
                            </Link>
                        </div>
                    </div>


                    <div className="bottom-footer m-3 pt-3 pb-3">
                        <div className="bottom-side-bar-text1 m-2">
                            1345 AVENUE OF THE AMERICAS, NEW YORK, NY, 10105, UNITED STATE
                        </div>
                        <div className="bottom-side-bar-text1 m-2 bottom-side-bar-weight">
                            © 2020 Project Rousseau Inc.
                        </div>
                        <div className="bottom-side-bar-text1 m-2 bottom-side-bar-weight">
                            Project Rousseau is a 501(c)(3) organization. Gifts are tax deductible to the full extent allowable under the law.
                        </div>
                    </div>


                </div>}
        </>

    );
}

export default SideBar;