import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store/store';



// Define the initial state using that type
const initialState: LanguageState = {
    selectedLanguage: 'ENG',
    languageList: ["ENG", "ESP"]
}

export const languageSlice = createSlice({
    name: 'lanage',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

        // Use the PayloadAction type to declare the contents of `action.payload`
        setLanguage: (state, action: PayloadAction<string>) => {
            state.selectedLanguage = action.payload;
        }
    }
})

export const { setLanguage } = languageSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getSelectedLanguage = (state: RootState) => state.languageState.selectedLanguage;
export const getLanguageList = (state: RootState) => state.languageState.languageList;


export default languageSlice.reducer