import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import logoWhite from '../../assets/logos/logo_white.png';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { setLanguage } from '../../store/languageSlice';

function Navbar(props: any) {

    const { toggleSideBarhandler, hideSideBar } = props;

    const [espToggle, setespToggle] = useState("ENG")

    // The `state` arg is correctly typed as `RootState` already
    const dispatch = useAppDispatch()


    const languageToggleHandler = () => {
        if (espToggle === "ENG") {
            setespToggle("ESP");
            dispatch(setLanguage("ESP"));

        }
        else if (espToggle === "ESP") {
            setespToggle("ENG");
            dispatch(setLanguage("ENG"));
        }

        // Change language state
    }

    const location = useLocation();

    const [openSideBar, setopenSideBar] = useState(false);

    const toggleLocalHandler = (event: React.MouseEvent, openState: boolean) => {
        setopenSideBar(openState);
        toggleSideBarhandler(openState);
    }

    useEffect(() => {
        setopenSideBar(hideSideBar);
    }, [hideSideBar]);



    return (
        <div className="navParent">
            <div className="blurDiv"></div>
            <div className='parentNavDiv d-flex justify-content-lg-between align-items-center m-lg-3'>
                <div className='topLeftDiv d-flex justify-content-between align-items-center'>
                    {!openSideBar && <div className='navBarIconClass' onClick={(e) => toggleLocalHandler(e, true)}>
                        <FontAwesomeIcon size="1x" icon={faBars} />
                    </div>}
                    {openSideBar && <div className='navBarIconClass' onClick={(e) => toggleLocalHandler(e, false)}>
                        <FontAwesomeIcon size="1x" icon={faXmark} />
                    </div>}
                    <div className='logoImageDiv'>
                        <Link to='/'>
                            <img src={logoWhite} alt="project rousseau logo" className='logoClass' />
                        </Link>
                    </div>
                </div>
                <div className='topRightDiv d-flex justify-content-center align-items-center' onClick={languageToggleHandler}>
                    <span> ENG</span>
                    {espToggle === "ENG" && <FontAwesomeIcon className='p-2 navBarIconClass' size="2x" icon={faToggleOff} />}
                    {espToggle === "ESP" && <FontAwesomeIcon className='p-2 navBarIconClass' size="2x" icon={faToggleOn} />}
                    <span> ESP</span>
                </div>
            </div>
            {location.pathname !== "/" && <div className="blueDiv"></div>}
        </div>
    );
}

export default Navbar;
