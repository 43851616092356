import axios from "axios";

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
} else {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_QA;
}

export default axios.create({
    headers: {
        "Content-type": "application/json"
    }
});

