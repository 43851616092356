import './ShelterForm.scss';
import ShelterDataService from '../../services/shelter.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import configurableData from '../../store/questionsUpdate.json';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { Dropdown } from 'react-bootstrap';


function ShelterForm() {
    // Question list items
    const questionArray = configurableData.questionSet;

    // Core states
    const [shelterFormPayload, setshelterFormPayload] = useState<{ [key: string]: any }>({});
    const [apiShelterFormPayload, setapiShelterFormPayload] = useState<ShelterPayload>({});
    const [prevAnswerSelected, setPrevAnswerSelected] = useState<string>('');
    const [currentQuestionObject, setCurrentQuestionObject] = useState<Question>(JSON.parse(JSON.stringify(questionArray[0])));
    const [questionAnswer, setQuestionAnswer] = useState([{ questionID: '', answer: '', answerEsp: '' }]);
    const [progressState, setprogressState] = useState({ currentCompletePercentage: 20 });


    // Helper states 
    const navigate = useNavigate();
    const [loader, setloader] = useState(false);
    const [showErrorText, setshowErrorText] = useState(false);

    // Language global states
    const language = useAppSelector(state => state.languageState.selectedLanguage)
    const [currentLanguage, setcurrentLanguage] = useState("ENG");
    const languageSetHandler = () => setcurrentLanguage(language);


    // Watchers for language and current question change
    useEffect(() => {
        languageSetHandler();
    }, [language]);

    useEffect(() => {


        let question = questionAnswer.find((question) => question.questionID === currentQuestionObject.id);
        // set answer to  PrevAnswerSelected state from Question local state evrytime currentquest changes which helps in maintaining back press logic
        if (question) {
            if (currentLanguage === "ESP") setPrevAnswerSelected(question.answerEsp);
            else setPrevAnswerSelected(question.answer);
        }
    }, [currentQuestionObject]);


    // logical methods
    const nextQuestionLogic = (backPressed: boolean = false) => {

        /* 
        * * Flow when first question and back pressed
        */
        if (backPressed && Number(currentQuestionObject.questionNumber) === 1) {
            navigate('/');
            return;
            // navigate to landing page
        }

        let questionWithEligibleAnswer: any;

        /* 
        * * Select the next Queston which is individual and does not depend on prev question which mean no eligible answer
        */
        for (let i = 0; i < questionArray.length; i++) {
            const element: any = questionArray[i];

            // if back pressed decrease number of question from current and roam to prev question
            if (backPressed && Number(element.questionNumber) === (Number(currentQuestionObject.questionNumber) - 1)) {
                questionWithEligibleAnswer = element;
                break;
            }

            // next question logic
            else if (Number(element.questionNumber) > Number(currentQuestionObject.questionNumber)) {
                questionWithEligibleAnswer = element;
                break;
            }


        }

        /* 
        * * Flow when no questions left
        */
        if (!questionWithEligibleAnswer) {

            setloader(true);
            let apiRequest: ShelterPayload = {
                "who": shelterFormPayload.who,
                "age": shelterFormPayload.age,
                "borough": shelterFormPayload.borough,
                "gender": shelterFormPayload.gender,
                "pregnant": shelterFormPayload.pregnant,
                "lgbtqPlus": shelterFormPayload.lgbtqPlus,
                "genderViolence": shelterFormPayload.genderViolence,
            };
            setapiShelterFormPayload(apiRequest);
            ShelterDataService.getAllShelters(apiRequest).then((response: any) => {
                setloader(false);

                navigate('/list', { state: { apiResponse: response.data, localData: questionAnswer } });
                return {};
            })
                .catch((e: Error) => {
                    setshowErrorText(true);
                    setloader(false);
                    console.log(e);
                });;
        }

        // update progress
        setprogressState({ currentCompletePercentage: ((Number(questionWithEligibleAnswer?.questionNumber) / questionArray.length) * 100) });



        return questionWithEligibleAnswer;
    }

    const setAnswerToPayload = (answerSelected: string, isSkipClicked: boolean = false) => {
        let localPayload: ShelterPayload = shelterFormPayload;

        switch (currentQuestionObject.payloadKey) {
            case "who":
                if (isSkipClicked) {
                    localPayload.who = '';
                    break;
                }
                if (answerSelected === "Just Me" || answerSelected === "Solo yo") localPayload.who = "M"
                if (answerSelected === "Me and my opposite-gender partner" || answerSelected === "Mi pareja de género opuesto y yo") localPayload.who = "OG"
                if (answerSelected === "Me and my same-gender partner" || answerSelected === "Mi pareja del mismo género y yo") localPayload.who = "SG"
                if (answerSelected === "Me, my partner, and our children" || answerSelected === "Mi pareja, mis niños y yo") localPayload.who = "P"
                if (answerSelected === "Me and my children" || answerSelected === "Mis niños y yo") localPayload.who = "C"
                break;
            case "age":
                if (isSkipClicked || answerSelected === "All ages" || answerSelected === "Todas las edades") {
                    localPayload.age = '';
                    break;
                }
                localPayload.age = answerSelected;
                break;
            case "gender":
                if (isSkipClicked) {
                    localPayload.gender = '';
                    break;
                }
                if (answerSelected === "Hombre" || answerSelected === "Male") localPayload.gender = "M"
                if (answerSelected === "Female" || answerSelected === "Mujer") localPayload.gender = "F"
                if (answerSelected === "Genderfluid" || answerSelected === "Género fluido") localPayload.gender = "G"
                if (answerSelected === "Nonbinary" || answerSelected === "No binario") localPayload.gender = "N"
                break;
            case "pregnant":
                if (isSkipClicked) {
                    localPayload.pregnant = '';
                    break;
                }
                if (answerSelected === "Yes" || answerSelected === "Si") localPayload.pregnant = "Y"
                if (answerSelected === "No") localPayload.pregnant = "N"
                break;
            case "lgbtqPlus":
                if (isSkipClicked) {
                    localPayload.lgbtqPlus = '';
                    break;
                }
                if (answerSelected === "Yes" || answerSelected === "Si") localPayload.lgbtqPlus = "Y"
                if (answerSelected === "No") localPayload.lgbtqPlus = "N"
                break;
            case "genderViolence":
                if (isSkipClicked) {
                    localPayload.genderViolence = '';
                    break;
                }
                if (answerSelected === "Yes" || answerSelected === "Si") localPayload.genderViolence = "Y"
                if (answerSelected === "No") localPayload.genderViolence = "N"
                break;
            case "borough":
                if (isSkipClicked) {
                    localPayload.borough = ``; break;
                }
                if (answerSelected === "Manhattan") localPayload.borough = "M"
                if (answerSelected === "Bronx") localPayload.borough = "BX"
                if (answerSelected === "Brooklyn") localPayload.borough = "BN"
                if (answerSelected === "Queens") localPayload.borough = "Q"
                if (answerSelected === "Staten Island") localPayload.borough = "S"
                break;

            default:
                break;
        }

        setshelterFormPayload(localPayload);
    }


    const skipQuestionHandler = (backPressed: boolean = false) => {
        return (event: React.MouseEvent) => {
            let tempArr: { questionID: string; answer: string; answerEsp: string; }[] = [];
            // check if question already in list
            let question = questionAnswer.find((question) => question.questionID === currentQuestionObject.id);
            if (question) {
                tempArr = [...questionAnswer];
                tempArr.map((quest) => {
                    if (quest.questionID === question?.questionID) {
                        quest.answerEsp = currentQuestionObject.optionsESP[currentQuestionObject.options.findIndex((item) => item === "")];
                        quest.answer = "";
                    }
                    return quest;
                })
            }
            // set question local state
            else {
                tempArr = [...questionAnswer, {
                    'questionID': currentQuestionObject.id,
                    'answer': "",
                    'answerEsp': currentQuestionObject.optionsESP[currentQuestionObject.options.findIndex((item) => item === "")]
                }];
            }
            setQuestionAnswer(tempArr)

            // set default answer to this question
            setAnswerToPayload("", true);
            setCurrentQuestionObject(JSON.parse(JSON.stringify(nextQuestionLogic(backPressed) || {})));

            event.preventDefault();
        }
    }

    const backQuestionHandler = () => {
        return (event: React.MouseEvent) => {
            // set previous question
            setCurrentQuestionObject(JSON.parse(JSON.stringify(nextQuestionLogic(true) || {})))

            event.preventDefault();
        }
    }

    const answerSelectionHandler = (event: React.MouseEvent, selectedAnswer: string) => {
        let tempArr: { questionID: string; answer: string; answerEsp: string; }[] = [];
        // check if question already in list
        let question = questionAnswer.find((question) => question.questionID === currentQuestionObject.id);
        if (question) {
            tempArr = [...questionAnswer];
            tempArr.map((quest) => {
                if (quest.questionID === question?.questionID) {
                    quest.answerEsp = currentQuestionObject.optionsESP[currentQuestionObject.options.findIndex((item) => item === selectedAnswer)] || selectedAnswer;
                    quest.answer = selectedAnswer;
                }
                return quest;
            })
        }
        // set question local state
        else {
            tempArr = [...questionAnswer, {
                'questionID': currentQuestionObject.id,
                'answer': selectedAnswer,
                'answerEsp': currentQuestionObject.optionsESP[currentQuestionObject.options.findIndex((item) => item === selectedAnswer)] || selectedAnswer,
            }];
        }
        setQuestionAnswer(tempArr)

        // payload update
        setAnswerToPayload(selectedAnswer);

        // trigger next question
        setCurrentQuestionObject(JSON.parse(JSON.stringify(nextQuestionLogic() || {})));


        event.preventDefault();
    }

    const retryHandler = () => {
        return (event: React.MouseEvent) => {
            setloader(true);
            ShelterDataService.getAllShelters(apiShelterFormPayload).then((response: any) => {
                setloader(false);

                navigate('/list', { state: JSON.stringify(response) });
            })
                .catch((e: Error) => {
                    setshowErrorText(true);
                    setloader(false);
                    console.log(e);
                });;
            event.preventDefault();
        }
    }


    return (
        <>
            {!loader && showErrorText && (
                <div className='align-items-center d-flex flex-column justify-content-center shelterLoader'>
                    <div className='questionSecondaryText'>
                        {currentLanguage === "ENG" ? 'We are facing some issues, please try again.' : 'Estamos teniendo algunos problemas, intenta nuevamente.'}
                    </div>
                    <div className='startOverDiv d-flex justify-content-center align-items-center'>
                        <div className='skipQuestionDiv d-flex align-items-center justify-content-center' onClick={retryHandler()}>
                            {currentLanguage === "ENG" ? 'Retry' : 'Rever'}
                        </div>
                    </div>
                </div>
            )}
            {loader && (<div className='align-items-center d-flex flex-lg-row flex-column justify-content-center shelterLoader'>
                <div className="loader"></div>
                <div className='loaderText'>
                    {currentLanguage === "ENG" ? 'Searching ...' : 'Buscando...'}
                </div>
            </div>
            )}
            {!showErrorText && !loader && (<div className="align-items-center d-flex justify-content-center shelterForm">

                <div className='questionaireParentDiv d-flex flex-column '>


                    <div className='d-flex justify-content-start mlShelter stepsProgressBarDiv'>
                        <div className='rectangleClass'>
                            <svg width="500" height="15" viewBox="0 0 500 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="500" height="15" rx="7" fill="#D9D9D9" />
                            </svg>

                        </div>
                        <div className='rectangleClass'>
                            <svg width="500" height="15" viewBox="0 0 500 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <rect width={(progressState.currentCompletePercentage / 100) * 500} height="15" rx="7" fill="#48DCCA" />
                            </svg>
                        </div>
                    </div>

                    <div className=' d-flex flex-column justify-content-start align-items-start questionDiv pt-lg-4 pb-4'>
                        <div className='questionPrimaryText d-flex justify-content-start '>
                            <div className='questionNumber'>
                                {currentQuestionObject.questionNumber}.
                            </div>
                            <div className='pl-3 mlShelter'>
                                {currentLanguage === "ENG" ? currentQuestionObject.question : currentQuestionObject.questionESP}
                            </div>
                        </div>
                        <div className='questionSecondaryText pt-2'>
                            {currentLanguage === "ENG" ? currentQuestionObject.subHeader : currentQuestionObject.subHeaderESP}
                        </div>
                    </div>

                    <div className='d-flex justify-content-start align-items-lg-center align-items-start answerOption p-lg-4 mlShelter radio-toolbar flex-lg-row flex-column'>
                        {currentQuestionObject.answerType.split(',').includes('dropdown') &&
                            <React.Fragment>
                                <Dropdown >
                                    <Dropdown.Toggle variant="success" id="ageDropdown">
                                        {(prevAnswerSelected && (prevAnswerSelected === 'All ages' || prevAnswerSelected === 'Todas las edades')) ? (currentLanguage === "ENG" ? "Select Age" : "Seleccionar edad") : (isNaN(Number(prevAnswerSelected)) ? (currentLanguage === "ENG" ? "Select Age" : "Seleccionar edad") : prevAnswerSelected)}
                                        {!prevAnswerSelected ? currentLanguage === "ENG" ? "Select age.." : "Seleccionar edad.." : ''}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {currentQuestionObject.dropdownList.map((item, index) =>
                                            <>
                                                <Dropdown.Item
                                                    key={item + "dropdown" + index}
                                                    onClick={(e) => answerSelectionHandler(e, item)}
                                                    eventKey={item}
                                                >{item}</Dropdown.Item>
                                            </>)}

                                    </Dropdown.Menu>
                                </Dropdown>
                            </React.Fragment>

                        }
                        {currentQuestionObject.answerType.split(',').includes('radio') && currentLanguage === "ENG" &&

                            currentQuestionObject.options.map((option, index) =>
                                <React.Fragment key={index + option}>
                                    <div className="m-3 radiobtn">
                                        <label className={`labelRadio ${(option === prevAnswerSelected) ? 'checkedRadio' : ""}`} for-html={"floatingInput" + index}>
                                            <input
                                                className='m-2'
                                                type="radio"
                                                id={"floatingInput" + index}
                                                key={'radio_btn' + index}
                                                value={option}
                                                checked={prevAnswerSelected === option}
                                                onChange={(e) => { }}
                                                onClick={(e) => answerSelectionHandler(e, option)} />
                                            {option}
                                        </label>
                                    </div>
                                </React.Fragment>
                            )
                        }
                        {currentQuestionObject.answerType.split(',').includes('radio') && currentLanguage === "ESP" &&

                            currentQuestionObject.optionsESP.map((option, index) =>
                                <>
                                    <div className="m-3 radiobtn">
                                        <label className={`labelRadio ${(option === prevAnswerSelected) ? 'checkedRadio' : ""}`} for-html={"floatingInput" + index}>
                                            <input
                                                className='m-2'
                                                type="radio"
                                                id={"floatingInput" + index}
                                                key={'radio_btn' + index}
                                                value={option}
                                                onChange={(e) => { }}
                                                checked={prevAnswerSelected === option}
                                                onClick={(e) => answerSelectionHandler(e, option)} />
                                            {option}</label>
                                    </div>
                                </>
                            )
                        }
                    </div>

                    <div className='questionActions d-flex justify-content-between p-4 mlShelter'>
                        <Link to='/'>
                            <div className='prevQuestionDiv d-flex align-items-center justify-content-center' onClick={backQuestionHandler()}>
                                <FontAwesomeIcon className='' icon={faArrowLeft} />
                            </div>
                        </Link>

                        <div className='d-flex justify-content-center align-items-center '>
                            <div className='skipQuestionDiv d-flex align-items-center justify-content-center' onClick={skipQuestionHandler()}>
                                {currentLanguage === "ENG" ? "Prefer not to answer" : "Prefiero no responder"}
                            </div>

                        </div>
                    </div>

                    <div className='mobileFooter align-items-center d-flex justify-content-between p-4'>
                        <Link to='/'>
                            <div className='prevQuestionDiv d-flex align-items-center justify-content-center' onClick={backQuestionHandler()}>
                                <FontAwesomeIcon className='' icon={faArrowLeft} />
                            </div>
                        </Link>

                        <div className='d-flex justify-content-center align-items-center '>
                            <div className='skipQuestionDiv d-flex align-items-center justify-content-center' onClick={skipQuestionHandler()}>
                                {currentLanguage === "ENG" ? "Prefer not to answer" : "Prefiero no responder"}
                            </div>

                        </div>
                    </div>


                </div>
            </div>)
            }
        </>

    );
}

export default ShelterForm;
