import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home/Home';
import ShelterForm from '../components/ShelterForm/ShelterForm';
import ShelterList from '../components/ShelterList/ShelterList';
// import Page404 from "./pages/404Page";
// import HomePage from "./pages/HomePage";
// import SubPage from './pages/SubPage';

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
function RouteConfig() {

    return (<Routes>
        <Route path='/' element={<Home />} />
        <Route path='/form' element={<ShelterForm />} />
        <Route path='/list' element={<ShelterList />} />
        {/* <Route component={Page404} /> */}
    </Routes>
    );
}

export default RouteConfig;
