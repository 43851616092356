import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useAppSelector } from '../../../store/hooks';
import './ShelterInfoModal.scss';

function ShelterInfoModal(props: any) {
    const { shelter } = props;
    const handleImageError = (e: any) => {
        e.target.style.display = 'none';
    }

    // Language global states
    const language = useAppSelector(state => state.languageState.selectedLanguage)
    const [currentLanguage, setcurrentLanguage] = useState("ENG");
    const languageSetHandler = () => setcurrentLanguage(language);
    const compelteAdd = shelter?.address.buildingNumber + ' ' + shelter?.address.streetName + ' ' + shelter?.address.city;

    // Watchers for language and current question change
    useEffect(() => {
        languageSetHandler();
    }, [language]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modalSize"
            centered
        >
            {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>

                <div className='modalParent d-flex flex-column jsutify-content-center align-items-start'>
                    <div className='headerName d-flex justify-content-between'>
                        <div className='shelterTitleModal '>

                            {shelter?.shelterName}
                        </div>
                        <div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => { props.onHide(); }}></button>
                        </div>
                    </div>

                    {shelter?.imgURLs && <div className='mt-3'>
                        <img onError={handleImageError} src={shelter.imgURLs[0].url} alt="shelter" className='shelterImgLarge' />
                    </div>}
                    <a href={`https://www.google.com/maps/search/` + compelteAdd} target="_blank">

                        <div className='shlterAddress mt-5'>
                            {compelteAdd}
                        </div>
                        <div className='mb-2 shelterSubtitleText'>
                            {currentLanguage === "ENG" ? 'Address' : 'Dirección'}
                        </div>
                    </a>


                    {shelter?.accessibleTrainLine.length !== 0 && (
                        <>

                            <div className='shlterAddress mt-3'>
                                {shelter?.accessibleTrainLine.join(', ')}
                            </div>
                            <div className='mb-2 shelterSubtitleText'>
                                {currentLanguage === "ENG" ? 'Accessible Trains' : 'Trenes Accesibles'}
                            </div>
                        </>
                    )}

                    {/* <div className='shlterAddress mt-3'>
                        10:00 am - 5:30 pm
                    </div>
                    <div className='shelterSubtitleText mb-2'>
                        {currentLanguage === "ENG" ? 'Registration Hours' : 'Horas de registro'}
                    </div> */}

                    <a href={`tel:${shelter?.shelterPhoneNumber}`}>
                        <div className='shlterAddress mt-3'>
                            {shelter?.shelterPhoneNumber}
                        </div>
                        <div className='shelterSubtitleText'>
                            {currentLanguage === "ENG" ? 'Contact' : 'Contacto'}
                        </div>
                    </a>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ShelterInfoModal;